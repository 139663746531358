@import "../../../assets/styles/style_grid.scss";
@import "../../../assets/styles/style_variables.scss";
@import "../../../assets/styles/style_mixins.scss";

@mixin card_interaction_badge {
  background-color: $bg_overlay_medium;
  padding: $padding_cells $padding_cells * 2;
  color: $color_white;
  z-index: 10;
  cursor: pointer;
  text-shadow: $box_shadow_default;
  font-size: $font_size_sm;
  border-radius: $border-radius-default / 2;
  .jdes-icons {
    color: $bg_overlay_hard;
    transform: translateY(0.1em);
    margin-right: $padding_cells;
  }
  &:hover {
    .jdes-icons {
      color: $color_yellow;
    }
  }
}

@mixin card_element() {
  flex-grow: 0;
  flex-shrink: 0;
  padding: $card_padding_outer;
  display: flex;
  position: relative;
  min-width: 0;
  .badge_left,
  .badge_right {
    position: absolute;
    top: $padding_cells;
    z-index: 50;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    > div {
      margin-bottom: $padding_cells;
    }
  }
  .badge_left {
    left: $padding_cells;
  }
  .badge_right {
    right: $padding_cells;
  }
  .overlay_link {
    &::after {
      display: none !important;
    }
    &:hover {
      background-color: transparent !important;
    }
  }
}

@mixin card_element_core() {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  border-radius: $border-radius-default / 2;
  overflow: hidden;
  @include animated(all, $animation_speed_default, $cubic_01);
}

@mixin card_element_title() {
  .pre_title {
    font-size: $font-size-menu;
    font-weight: $font_weight_bold;
  }

  .sub_title {
    font-size: $font-size-menu;
    font-weight: $font_weight_bold;
    padding-bottom: $padding_default / 5;
  }

  .title {
    width: 100%;
    font-size: $font-size-primary;
    padding-bottom: $padding_default / 5;
    & + .sub_title {
      transform: translateY(-($padding_default / 5));
    }
  }
}

.card {
  @include card_element();
  .card_core {
    @include card_element_core();
    .svgIcon {
      width: 100%;
      img {
        width:20%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .fav {
    @include card_interaction_badge();
  }



  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }

  .card_content {
    display: block;
    padding: $card_padding_inner;
    z-index: 2;
    position: relative;
    flex-grow: 1;
  }

  .card_pre_title {
    font-size: $font-size-menu;
    font-weight: $font_weight_bold;
    padding-bottom: $padding_cells;
  }

  .card_sub_title {
    font-size: $font-size-menu;
    font-weight: $font_weight_bold;
    padding-top: $padding_cells;
    text-overflow: clip;
    text-overflow: ellipsis;
    text-overflow: "…";
    word-break: break-word;
    display: flex;
    flex-wrap: wrap;
    min-width: 0;
    position: relative;
    width: 100%;
    max-width: 100%;
  }

  .card_title {
    width: 100%;
    min-width: 0;
    font-size: $font-size-primary;
    padding-bottom: $padding_cells /2;
    line-height: 1;
    display: flex;
    flex-direction: column;
  }

  .card_text {
    width: 100%;
    font-size: $font-size-menu;
  }

  .card_illustration {
    background-size: cover;
    background-position: 50% 25%;
    z-index: 1;
    @include animated(opacity, $animation_speed_default);
    //& + div {
    //  padding-top: $card_padding_inner / 2;
    //}
  }

  &.card_default {
    order: 1;
  }
  &.card_default,
  &.card_premium {
    .card_core {
      background-color: $bg_overlay_dark_extra_soft;
    }
    z-index: 1;
    @include animated(all, $animation_speed_fast, $cubic_01);
    &:hover,
    &:focus {
      z-index: 2;
    }
    &:active {
      transform: scale(0.95);
    }
    @media screen and (max-width: $screen-sm-max) {
      height: auto;
      .card_core {
        flex-direction: row;
      }
      .card_illustration {
        flex-basis: 30%;
        flex-shrink: 0;
      }
    }
  }


  &.card_default,
  &.card_premium,
  &.card_glass_bright,
  &.card_glass_dark {
    .card_illustration {
      position: relative;
      padding-top: 25%;
      background-color: $color_main;
    }
  }

  &.card_cta_card {
    text-align: center;
    flex-grow: 1;
    .card_core {
      padding-top: $padding_default / 2;
      border: 1px solid $bg_overlay_dark_soft !important;
      &:hover {
        //background-color: $bg_overlay_dark_extra_soft;
        transform: scale(1.05);
      }
    }
    .svgIcon {
      width: 100%;
      img {
        width:20%;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .card_title {
      font-size: $font_size_h3;
    }
  }

  $accent_border_height: 4px;
  &.card_accent_red,
  &.card_accent_green,
  &.card_accent_blue,
  &.card_accent_orange,
  &.card_accent_pink,
  &.card_accent_yellow {
    .card_core {
      border-bottom: $accent_border_height solid;
    }
    @media screen and (max-width: $screen-sm-max) {
      &:not(.card_cta_card) {
        .card_core {

        }
      }
    }
  }

  &.card_accent_red {
    .card_core {
      border-color:$color_red;
    }
  }
  &.card_accent_green {
    .card_core {
      border-color: $color_green;
    }
  }
  &.card_accent_blue {
    .card_core {
      border-color: $color_blue;
    }
  }
  &.card_accent_orange {
    .card_core {
      border-color: $color_orange;
    }
  }
  &.card_accent_pink {
    .card_core {
      border-color: $color_pink;
    }
  }
  &.card_accent_yellow {
    .card_core {
      border-color: $color_yellow;
    }
  }




  @media screen and (max-width: $screen-sm-max) {
    &.card_default,
    &.card_premium {
      .card_core {
        .card_illustration {
          padding-top: 42%;
        }
      }
    }
  }
}
.card_extension {
  font-size: $font-size_additional_info;
}

/********************************************** ANIMATIONEN **************************************************/

.scc_tripple {
  .card {
    z-index: 1;
    opacity: 1;
    @include animated(all, $animation_speed_slow, $cubic_01);
    &:nth-child(2) {
      z-index: 2;
    }
  }
}



.add_card {
  @include card_element;
  order: 100;
  opacity: 0.2;
  min-height: 150px;
  flex-grow: 0;
  @include animated(all, $animation_speed_default);
  .add_card_core {
    @include card_element_core;
    background-color: $color_green;
    position: relative;
    cursor: pointer;
    .jdes-icons {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      font-size: 120px;
      color: $color_white;
    }
  }
  &:hover {
    opacity: 0.4;
  }
}