@import "../../../assets/styles/style_grid";
@import "../../../assets/styles/style_variables";
@import "../../../assets/styles/style_mixins";

.headline_block_spacer {
  width: 100%;
  display: block;
  padding: $padding_cells * 2;
}

.headline {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  h1,h2,h3,h4,h5,h6 {
    width: 100%;
    flex-basis: 100%;
    flex-grow: 1;
    text-align: left;
  }
  .btn {
    font-size: $font-size_additional_info;
  }
  &.interaction_header {
    flex-direction: row;
  }
  .headline_toggle_info {
    display: none;
  }
  @media screen and (max-width: $screen-md-max) {
    .interactions {
      display: none;
    }
  }
  @media screen and (max-width: $screen-sm-max) {
    .headline_toggle_info {
      display: block;
    }

    &.mobile_toggler {
      flex-direction: row;
      background-color: $bg_overlay_dark_extra_soft;
      padding: $padding_default / 2;
      .headline_block_spacer {
        display: none;
      }
      &:hover {
        background-color: $bg_overlay_dark_extra_soft;
      }
      h1,h2,h3,h4,h5,h6 {
        font-size: $font_size_h4;
      }
      margin-bottom: $padding_cells;
    }
    &.closed {
      + div {
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
        max-height: 0;
        overflow: hidden;
      }
    }
  }
}