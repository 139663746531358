@import "style_grid";
@import "style_variables";

@mixin dialogue_overlay_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $bg_overlay_dark_hard;
  display: flex;
  align-items: center;
}
@mixin dialogue_window {
  margin: 0 auto;
  width: 100%;
  background-color: $color_white;
  border-radius: $border-radius-default;
  text-align: center;
  position: relative;
  box-shadow: $box_shadow_default;
}

@mixin badge() {
  font-size: 12px;
  font-weight: $font_weight_bold;
  background-color: $color_main;
  border-radius: $border-radius-extra $border-radius-micro $border-radius-extra $border-radius-micro;
  color: $color_white;
  padding: $padding_cells $padding_cells * 2 $padding_cells * 0.8 $padding_cells * 2;
  display: inline-block;
  text-shadow: none;
}

@mixin pill() {
  font-size: 10px;
  color: $color_secondary;
  font-weight: $font_weight_bold;
  background-color: $bg_overlay_dark_soft;
  border-radius: $border-radius-default $border-radius-micro $border-radius-default $border-radius-micro;
  padding: $padding_cells / 2 $padding_cells $padding_cells / 2 * 0.8 $padding_cells;
  display: inline-block;
  text-shadow: none;
}

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin jdes-icons() {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'jdes-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$skewing: 1.3deg;
@mixin visible_pseudo_abs() {
  content: '';
  display: block;
  position:absolute;
  height: 100%;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;
}
@mixin ascending_top($background: inherit) {
  &::before {
    @include visible_pseudo_abs();
    top: 0;
    transform-origin: 0 0;
    //transform: skewY(-$skewing);
    //background: $background;
    background-size: cover;
    background-position: 50% 50%;
  }
}

@mixin list_icon() {
  position: relative;
  padding-right: $padding_default * 1.2;
  &:before {
    @include jdes-icons();
    content: '\e90e';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: $color_accent;
    font-size: 1em;
    left: $padding_default / 3;
  }
}

@mixin list_item_default_style() {
  position: relative;
  padding-left: $padding_default * 1.2;
  @include list_icon();
}

@mixin transition($time) {
  $transition: all $time ease;
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

@mixin centered_loading_circle {
  .loading_circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}

$cubic_01: cubic-bezier(.67,.11,.08,1.9);

@mixin animated($property, $time, $ease : ease-out) {
  -webkit-transition: $property $time $ease;
  -moz-transition: $property $time $ease;
  -o-transition: $property $time $ease;
  transition: $property $time $ease;
}

@mixin glass_filter () {
  @supports (backdrop-filter: blur(8px)) {
    backdrop-filter: blur(12px) brightness(0.97) hue-rotate(0deg);
    background-color: transparent;
  }
}

@mixin glass_filter_2 () {
  @supports (backdrop-filter: blur(8px)) {
    backdrop-filter: blur(22px) brightness(0.5) hue-rotate(0deg) saturate(0.2);
    background-color: transparent;
  }
}

@mixin gradient($color_01, $color_02, $degrees: 90deg, $start_percentage: 0%, $end_percentage: 100%) {
  /* Old browsers */
  background: $color_01; /* Old browsers */
  /* FF3.6-15 */
  background: -moz-linear-gradient($degrees, $color_01 $start_percentage, $color_02 $end_percentage);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-linear-gradient($degrees, $color_01 $start_percentage, $color_02 $end_percentage);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  background: linear-gradient($degrees, $color_01 $start_percentage, $color_02 $end_percentage);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color_01', endColorstr='$color_02',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

@-webkit-keyframes gradient_animation_basic {
  0%{background-position:93% 0%}
  50%{background-position:0% 100%}
  100%{background-position:93% 0%}
}
@-moz-keyframes gradient_animation_basic {
  0%{background-position:93% 0%}
  50%{background-position:0% 100%}
  100%{background-position:93% 0%}
}
@keyframes gradient_animation_basic {
  0%{background-position:93% 0%}
  50%{background-position:0% 100%}
  100%{background-position:93% 0%}
}

@mixin gradient_animated($color_01, $color_02, $degrees: 90deg, $animation_speed: 20s, $animation_name:'gradient_animation_basic', $animation_ease: ease) {
  @include gradient($color_01, $color_02, $degrees);
  background-size: 400% 400%;
  -webkit-animation: $animation_name $animation_speed $animation_ease infinite;
  -moz-animation: $animation_name $animation_speed $animation_ease infinite;
  animation: $animation_name $animation_speed $animation_ease infinite;
}



@keyframes rotate {
  0%{transform:rotateZ(0)}
  100%{transform:rotateZ(360deg)}
}

@mixin animaterotation($speed, $animation_ease: linear) {
  -webkit-animation: rotate $speed $animation_ease infinite;
  -moz-animation: rotate $speed $animation_ease infinite;
  animation: rotate $speed $animation_ease infinite;
}


// keyframes mixin
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin inline_text_link {

}

@mixin container(){
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  @media screen and (min-width: $screen-xs-min) {
    max-width: $screen-xs-width;
  }
  @media screen and (min-width: $screen-sm-min) {
    max-width: $screen-xs-width;
  }
  @media screen and (min-width: $screen-md-min) {
    max-width: $screen-md-width;
  }
  @media screen and (min-width: $screen-lg-min) {
    max-width: $screen-lg-width;
  }
  @media screen and (min-width: $screen-xl-min) {
    max-width: $screen-xl-width;
  }
}

@mixin background_text_clip() {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @-moz-document url-prefix() {
    background: transparent !important;
    -webkit-text-fill-color: $font_color_headline;
  }
}

@mixin content_block {
  padding: 0 0 $default_container_padding 0;
}

@mixin borderElement($background : $bg_overlay_dark_soft) {
  background-color: $background;
  //border: 2px solid $bg_overlay_dark_soft;
  border-radius: $border-radius-extra;
}
@mixin borderElementInteractionItem() {
  border-radius: $border-radius-default;
  font-size: $font_size_menu;
  font-weight: $font_weight_bold;
  padding: $padding_cells * 2 $padding_cells * 2 $padding_cells * 1.7 $padding_cells * 2;
  cursor: pointer;
  @include animated(all, $animation_speed_default);
  &:hover,
  &.active {
    background-color: $color_secondary;
    color: $color_white;
  }
}

@mixin abs_overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
}
@mixin visible_subnavmenu() {
  opacity: 1;
  transform: translateY(100%);
  pointer-events: auto;
  background-color: $color_white;
}

