@import "../../../assets/styles/style_grid.scss";
@import "../../../assets/styles/style_variables.scss";
@import "../../../assets/styles/style_mixins.scss";

@mixin headlineColor($color: $color_white) {
  h1,h2,h3,h4,h5,h6 {
    color: $color;
  }
}

@mixin giantCTALink($color: $color_white) {
  color: $color;
  display: inline-block;
  font-weight: $font_weight_bold;
  border: 4px solid $color;
  padding: $padding_default $padding_default * 2;
  border-radius: $border-radius-extra*2;
}

@mixin infoAdd() {
  display: block;
  width: 100%;
  text-align: center;
  font-size: $font_size_sm;
  position: absolute;
  bottom: -$padding_default;
}

.block {
  display: block;
  position: relative;
  width: 100%;
  z-index: 0;
  background-size: cover;
  background-position: 50% 25%;
  background-repeat: no-repeat;
  .block_core {
    z-index: 3;
    position: relative;
  }
  .block_title {
    padding-top: 2rem;
    transform: translateY(2rem);
    text-align: center;
    font-size: $font_size_h2;
  }
  &:not(.block_full)
  {
    .block_core {
      @include container;
    }
  }
  &.highlight,
  &.highlight_2 {
    color: $font_color_white;
    padding: $padding_default * 2 0;
    margin-bottom: $padding_default;
    text-align: center;
    @include headlineColor;
    h1,h2,h3,h4,h5,h6 {
      margin-bottom: $padding_default /2 ;
      margin-top: $padding_default /2;
    }
    cite {
      width: 100%;
      margin: 0;
      a {
        @include giantCTALink();
      }
    }
    span {
      @include infoAdd();
    }

  }
  &.highlight,
  &.highlight_2 {

  }
  &.highlight {
    @include gradient($color_secondary, $color_main, 45deg);
  }
  &.highlight_2 {
    @include gradient($color_main, $color_secondary);
  }
  &.emphasized {
    color: $font_color_white;
    @include headlineColor($color_main);
    .block_core {
      padding-top: $default_container_padding;
    }
    @include bg_color_secondary_rgba(1);
  }

  @media screen and (max-width: $screen-lg-max) {
    &:not(.block_full) {
      padding-left: $padding_large_screen_sides;
      padding-right: $padding_large_screen_sides;
    }
  }

  @media screen and (max-width: $screen-md-max) {
    &:not(.block_full) {
      padding-left: $padding_small_screen_sides;
      padding-right: $padding_small_screen_sides;
    }
  }
}

.content_block {
  .block_core {
    @include content_block;
  }
}