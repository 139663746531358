@import "../../../assets/styles/style_variables.scss";
@import "../../../assets/styles/style_mixins.scss";

.bildnachweis {
  font-size: $font-size_micro;
  padding: $padding_cells;
  margin-bottom: $padding_cells / 2;
  background-color: $bg_overlay_dark_ultra_soft;
  border-radius: $border-radius-default / 2;
  strong{
    display: block;
  }
  div {
    opacity: 0.6;
    a {
      color: $font_color_base;
      font-weight: $font_weight_bold;
      &:hover {
        color: $color_accent;
      }
    }
  }
}