@import "../../../assets/styles/style_mixins.scss";
@import "../../../assets/styles/style_variables.scss";
@import "../../../assets/styles/style_grid.scss";

.badge {
  display: inline-block;
  position: relative;
  font-size: $font_size_micro;
  font-weight: $font_weight_bold;
  padding: $padding_cells $padding_cells * 2;
  background-color: $bg_overlay_dark_soft;
  border-radius: $border-radius-default / 2;
  color: $font_color_base;
  margin: 0 $padding_cells $padding_cells 0;
  &.badge_blue {
    color: $color_white;
    background-color: $color_secondary;
  }
  &.badge_red {
    color: $color_white;
    background-color: $color_red;
  }
}