@import "../../../assets/styles/style_mixins.scss";
@import "../../../assets/styles/style_variables.scss";
@import "../../../assets/styles/style_grid.scss";

footer {
  min-height: 100px;
  padding: $padding_default 0;
  margin-top: $padding_default;
  //@include gradient($color_main, $color_dark, 355deg);
  background-color: $color_white;
  .block_core {
    display: flex;
    flex-wrap: wrap;
    > div {
      flex-basis: 25%;
      flex-shrink: 0;
    }
    .footer_logo {
      display: flex;
      img {
        width: 100px;
      }
    }
    .logo_box {
      display: flex;
    }
  }
  .footer_box {
    nav {
      display: flex;
      flex-direction: column;
      a {
        color: $font_color_base;
        font-size: $font-size_additional_info;
        &:hover {
          color: $color_orange;
        }
      }
    }
    @media screen and (max-width: $screen-sm-max) {
      nav {
        border: none;
        margin: 0;
      }
      a {
        width: 100%;
        text-align: center;
        padding: $padding_default / 4 0;
        background-color: $bg_overlay_dark_ultra_soft;
        margin-bottom: 2px;
        &:hover {
          background-color: $bg_overlay_dark_extra_soft;
        }
      }
    }
  }
  .legal_links {
    nav {
      display: flex;
      width: 100%;
      border-top: 1px solid $bg_overlay_dark_soft;
      margin-top: $padding_default / 2;
      padding-top: $padding_default / 2;
      flex-wrap: wrap;
    }
    a {
      display: block;
      font-size: $font_size_micro;
      padding: 0 $padding_default / 4;
      color: $font_color_base;
      &:hover {
        color: $color_orange;
      }
    }
    @media screen and (max-width: $screen-sm-max) {
      nav {
        border: none;
        margin: 0;
      }
      a {
        width: 100%;
        text-align: center;
        padding: $padding_default / 4 0;
        background-color: $bg_overlay_dark_ultra_soft;
        margin-bottom: 2px;
        &:hover {
          background-color: $bg_overlay_dark_extra_soft;
        }
      }
    }
  }
  @media screen and (max-width: $screen-sm-max) {
    .block_core {
      > div {
        flex-basis: 100%;
      }
      .footer_logo {
        padding: $padding_default 0;
        display: block;
        width: 100%;
        text-align: center;
        img {
          width: 120px;
        }
      }
    }
  }
}