@import "../../../assets/styles/style_grid";
@import "../../../assets/styles/style_variables";
@import "../../../assets/styles/style_mixins";

.info_section {
  display: flex;
  align-items: center;
  padding: $padding_default * 3 0;
  width: 100%;
  position: relative;
  .info_section_content {
    flex-basis: 52%;
    flex-shrink: 0;
    z-index: 2;
    .info_section_title {
      font-family: $font_family_body;
      font-size: $font_size_h1;
      line-height: 1;
      padding-bottom: $padding_default / 2;

    }
    .info_section_text {
      .step_box {
        padding-top: $padding_default / 2;
      }
    }
  }
  .info_section_link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    z-index: 3;
  }
  .info_section_illustration {
    position: absolute;
    width: 62%;
    right: 0;
    top: 50%;
    opacity: 1;
    transform: translateY(-50%) translateX(15%) scale(0.8);
    z-index: 1;
    min-height: 200px;
    //background-color: $color_main;
  }
  &.reverse {
    .info_section_illustration {
      right: auto;
      left: 0;
      transform: translateY(-50%) translateX(-22%) scale(0.8                                        );
    }
  }
  @media screen and (max-width: $screen-sm-max){
    padding: $padding_default * 2 0 $padding_default 0;
    .info_section_content {
      flex-basis: 100%;
    }
    .info_section_illustration {
      width: 100%;
      opacity: 0.1;
      img {
        transform: translateY(-25%);
      }
    }
    &.reverse {
      .info_section_illustration {
        transform: translateX(-20%);
      }
    }
    &:not(.reverse) {
      .info_section_illustration {
        transform: translateY(-25%)  translateX(20%);
      }
    }
  }
}