@import "../../../assets/styles/style_grid";
@import "../../../assets/styles/style_variables";
@import "../../../assets/styles/style_mixins";


$hero_content_ratio: 32%;
$hero_content_ratio_tablet: 38%;
$hero_content_ratio_mobile: 55%;

.hero {
  display: block;
  width: 100%;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-color: $color_secondary;
  .hero_core {
    display: block;
    width: 100%;
    padding-top: $hero_content_ratio;
    position: relative;
  }
  @media screen and (max-width: $screen-md-max){
    .hero_core {
      padding-top: $hero_content_ratio_tablet;
    }
  }
  @media screen and (max-width: $screen-sm-max){
    .hero_core {
      padding-top: $hero_content_ratio_mobile;
    }
  }
}

.startpage_hero_content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  width: 100%;
  text-align: left;
  color: $color_white;
  text-shadow: 1px 1px 12px rgba(0,0,0,0.9);
  .main_title {
    font-family: $font_family_body;
    font-weight: 600;
    font-size: $font_size_h1 * 2;
    line-height: 1;
    padding-bottom: $padding_cells * 2;
  }
  .sub_title {
    font-weight: $font_weight_bold;
    font-size: $font_size_h3;
    line-height: 1.2;
    max-width: 600px;
  }
  @media screen and (max-width: $screen-md-max){
    .main_title {
      font-size: $font_size_h2;
    }
    .sub_title {
      font-size: $font_size_h4;
    }
  }
  @media screen and (max-width: $screen-sm-max){
    .main_title {
      font-size: $font_size_h1;
    }
    .sub_title {
      font-size: $font_size_h4;
    }
  }
}