@import "style_mixins.scss";

.jdes-animated-icons {
  position: relative;
  display: inline-block;
  width: 1em;
  line-height: 1em;
  .jdes-icons {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    color: inherit;
    font-size: inherit;
    @include animated(all, 0.15s);
  }
}
.jdes-animated-icon-menu-burger {
  .menu_burger_center_02 {
    opacity: 0;
  }
  &.active {
    @extend .jdes-animated-icon-menu-burger_active;
  }
}

.jdes-animated-icon-menu-burger_active {
  .jdes-icons-menu-burger-top,
  .jdes-icons-menu-burger-bottom {
    opacity: 0;
  }
  .jdes-icons-menu-burger-top {
    transform: translateX(-1.2em);
  }
  .jdes-icons-menu-burger-bottom {
    transform: translateX(1.2em);
  }
  .menu_burger_center_01,
  .menu_burger_center_02 {
    transform-origin: 50% 50%;
    opacity: 1;
  }
  .menu_burger_center_01 {
    transform: rotateZ(-45deg);
  }
  .menu_burger_center_02 {
    transform: rotateZ(45deg);
  }
}

.jdes-animated-icon-checkbox {
  .checkbox-frame {
    opacity: 0;
  }
  .checkbox-hook {
    opacity: 0;
  }
  &.active {
    @extend .jdes-animated-icon-menu-burger_active;
  }
}

