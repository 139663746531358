@import "../../../assets/styles/style_mixins.scss";
@import "../../../assets/styles/style_variables.scss";
@import "../../../assets/styles/style_grid.scss";

$banner_height: 100px;

header {
  //@include gradient($color_main, $color_dark, 175deg);
  height: $banner_height;
  //z-index: 100;
  background-color: $color_white;
  position: relative;
  box-shadow: $box_shadow_default;
  margin-top: 20px;
  .logo {
    position: relative;
    a {
      display: block;
      img {
        width: 130px;
      }
    }
    @include animated(all, $animation_speed_fast, $cubic_01);
    &:hover {
      transform: scale(1.05);
    }
    &:active {
      transform: scale(0.92);
    }
  }
  .block {
    height: inherit;
  }
  .block_core {
    display: flex;
    position: relative;
    align-items: center;
    height: inherit;
  }

  .banner_core {
    display: flex;
    position: relative;
  }
}

.main_menu {
  height: inherit;
  display: flex;
  margin-right: -15px;
  a {
    font-size: $font-size_additional_info;
    padding: 35px 15px;
    font-weight: $font_weight_bold;
    color: $font_color_base;
    &.active{
      background-color: $bg_overlay_dark_ultra_soft;
    }
    &:hover {
      background-color: $bg_overlay_dark_ultra_soft;
    }
  }
  @media screen and (max-width: $screen-sm-max) {
    display: none;
    visibility: hidden;
    pointer-events: none;
  }
}
.mobile_menu {
  @media screen and (min-width: $screen-md-min) {
    display: none;
    visibility: hidden;
    pointer-events: none;
  }
  .jdes-animated-icons {
    font-size: 42px;
    cursor: pointer;
    @media screen and (min-width: $screen-md-min) {
      display: none;
      visibility: hidden;
      pointer-events: none;
    }
  }
}

.mobile_menu_container {
  position: absolute;
  z-index: 100;
  right: 0;
  top: 0;
  transform: translateY(0%);
  background-color: $color_white;
  display: flex;
  flex-direction: column;
  opacity: 0;
  pointer-events: none;
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
  @include animated(all, $animation_speed_default, $cubic_01);
  a {
    transform: translateY(4%);
    color: $font_color_base;
    padding: $padding_default / 2 $padding_default;
    &:hover {
      background-color: $bg_overlay_dark_soft;
    }
    &.close_menu {
      background-color: $bg_overlay_dark_extra_soft;
      font-weight: $font_weight_bold;
    }
  }
  &.active {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0%);
    a {
      transform: translateY(0%);
    }
  }
  @media screen and (min-width: $screen-md-min){
    display: none;
    pointer-events: none;
    opacity: 0;
  }
}

.language_header {
  font-size: $font_size_micro;
  position: absolute;
  top: -20px;
  height: 20px !important;
  display: flex;
  cursor: pointer;
  //@include gradient($color_secondary, $color_main);
  background-color: #efefef;
  div {
    display: flex;
  }
  .language_selector {
    font-weight: $font_weight_bold;
    margin-left: $padding_cells;
    &:hover {
      color: $color_accent;
    }
  }
}