@import "../../../assets/styles/style_variables.scss";
@import "../../../assets/styles/style_mixins.scss";


.step_box {
  .step_content {
    padding: $padding_default / 2;
    width: 100%;
    border-radius: $border-radius-default;
    transform: translateY(-$padding_default / 4);
    @include animated(all, $animation_speed_slow);
    .title {
      font-weight: $font_weight_bold;
    }
    .text {
      font-size: $font-size_additional_info;
      height: 0;
      max-height: 0;
      overflow: hidden;
      pointer-events: none;
      @include animated(all, $animation_speed_slow);
    }
    &:hover {
      cursor: pointer;
      background-color: $bg_overlay_dark_extra_soft;
    }
  }
  .step_number {
    padding-right: $padding_default / 3;
    .step_number_wrapper {
      width: 40px;
      height: 40px;
      background-color: $bg_overlay_dark_medium;
      color: $color_white;
      border-radius: 100%;
      display: flex;
      align-items: center;
      span {
        font-weight: $font_weight_bold;
        text-align: center;
        width: 100%;
        font-size: $font_size_h4;
        transform: translateY(-0.12em);
      }
    }
  }
  .step {
    display: flex;
    width: 100%;
    margin-bottom: $padding_cells * 2;
    &.active {
      .step_number {
        .step_number_wrapper {
          background-color: $color_main;
        }
      }
      .step_content {
        background-color: $bg_overlay_dark_extra_soft;

        .title {
          color: $color_main;
        }
        .text {
          padding-top: $padding_cells * 2;
          height: initial;
          max-height: 10000px;
          overflow: initial;
          pointer-events: auto;
        }
      }
    }
  }
}