@import "style_grid.scss";
@import "style_variables.scss";
@import "style_mixins.scss";
@import "jdes_icons.scss";
@import "jdes_icons_animated.scss";
@import "style_fonts.scss";

.entry_title_area {
  .entry_title {
    font-weight: $font_weight_bold;
    line-height: 1.1;
    display: block;
    font-size: $font_size_rem;
    margin-bottom: $padding_cells;
  }

  .entry_title_creation_time {
    font-size: $font_size_micro;
    font-weight: $font_weight_bold;
    border-radius: $border-radius-default / 2;
    display: inline-block;
    margin-bottom: $padding_cells;
    line-height: 1.2;
    opacity: 0.6;
  }
}


.help_item_list_view {
  .help_item_core {
    padding: $padding_default / 2;
    background-color: $bg_overlay_dark_extra_soft;
    border-radius: $border-radius-default;
    display: flex;
    .help_item_pills {
      margin-top: $padding_cells * 2;
    }
    .help_item_extended_info {
      border-top: 0 solid transparent;
      margin-top: 0;
      padding-top: 0;
      max-height: 0;
      overflow: hidden;
      font-size: $font-size_additional_info;
      label {
        font-weight: $font_weight_bold;
        display: block;
      }
      .the_description {

      }
      @include animated(all, $animation_speed_default, $cubic_01);
      .help_item_extended_info_title {
        font-weight: $font_weight_bold;
      }
      .help_item_extended_info_description {
        padding-bottom: $padding_cells * 2;
      }
    }
    &.show_details {
      .help_item_extended_info {
        border-top: 1px solid $bg_overlay_dark_extra_soft;
        margin-top: $padding_default / 4;
        padding-top: $padding_default / 4;
        max-height: 10000px;
      }
    }
    .help_item_info {
      flex-grow: 1;
      padding-right: $padding_default / 2;
    }
    .help_item_interaction {
      padding-left: $padding_default / 2;
      border-left: 1px solid $bg_overlay_dark_soft;
      flex-shrink: 0;
      z-index: 1;
      .btn {
        font-size: $font_size_micro;
      }
    }
    @media screen and (max-width: $screen-sm-max){
      flex-direction: column;
      .help_item_interaction {
        padding: $padding_default / 2 0 0 0;
        border-left: none;
        .btn {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
  .help_item_pill_title {
    font-size: $font-size_additional_info;
    font-weight: $font_weight_bold;
  }
}