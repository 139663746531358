$screen-xs-width: 100%;
$screen-xs-min: 0;
$screen-xs-max: 366px;

$screen-sm-width: 100%;
$screen-sm-min: 367px;
$screen-sm-max: 767px;

$screen-md-width: 767px;
$screen-md-min: 768px;
$screen-md-max: 991px;

$screen-lg-width: 991px;
$screen-lg-min: 992px;
$screen-lg-max: 1199px;

$screen-xl-width: 1199px;
$screen-xl-min: 1200px;