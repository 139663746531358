@import "../../../assets/styles/style_mixins.scss";
@import "../../../assets/styles/style_variables.scss";
@import "../../../assets/styles/style_grid.scss";

.element_collection {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$collection_padding_outer;
  margin-right: -$collection_padding_outer;
  > {
    div {
      padding: $collection_padding_outer;
    }
  }
  > div {
    flex-grow: 0;
  }
  &.grow_items {
    > div {
      flex-grow: 1;
    }
  }
  &.row_default{
    > div {
      flex-grow: 0;
    }
  }
  &.row_single{
    > div {
      flex-basis: 100%;
      width: 100%;
    }
  }
  &.row_duo{
    > div {
      flex-basis: 50%;
      width: 50%;
    }
  }
  &.row_tripple{
    > div {
      flex-basis: 33.33333%;
      width: 33.33333%;
    }
  }
  &.row_quad{
    > div {
      flex-basis: 25%;
      width: 25%;
    }
  }
  &.row_penta{
    > div {
      flex-basis: 20%;
      width: 20%;}
  }
  &.row_deca{
    > div {
      flex-basis: 10%;
      width: 10%;
    }
  }
  &.hzm {
    @media screen and (max-width: $screen-sm-max) {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: scroll;
      > div {
        flex-shrink: 0;
        flex-grow: 0;
      }
    }
  }
  @media screen and (max-width: $screen-md-max) {
    &.row_deca{
      > div {
        flex-basis: 16.666666%;
      }
    }
  }
  @media screen and (max-width: $screen-sm-max) {
    &.row_tripple,
    &.row_quad,
    &.row_penta {
      > div {
        flex-basis: 100%;
        width: 100%;
      }
    }
    &.row_deca{
      > div {
        flex-basis: 20%;
        width: 20%;
      }
    }
  }
  @media screen and (max-width: $screen-xs-max) {
    &.row_duo,
    &.row_tripple,
    &.row_quad,
    &.row_penta {
      > div {
        flex-basis: 100%;
        width: 100%;
      }
    }
  }
  &.mobile_full {
    @media screen and (max-width: $screen-sm-max) {
      > div {
        flex-basis: 100%;
        width: 100%;
      }
    }
  }
}

.sc_collection {

  //padding-bottom: $padding_default;
  &.scc_mono {
    .spaces_card  {
      flex-basis: 100%;
    }
  }
  &.scc_duo {
    .spaces_card  {
      flex-basis: 50%;
    }
  }
  &.scc_tripple {
    .spaces_card  {
      flex-basis: 33.3333%;
      flex-grow: 0;
    }
  }
  &.scc_quad {
    .spaces_card  {
      flex-basis: 25%;
      flex-grow: 0;
    }
  }

  &.cta_row {
    flex-wrap: nowrap;
  }

  &.scc_menu {
    display: none;
    padding: 0 0 $default_container_padding 0;
    @media screen and (max-width: $screen-sm-max) {
      display: flex;
    }
  }


  &.scc_scroll_swipe {
    display: block;
    text-align: center;
    .breheimen_card {
      display: inline-block;
      width: 20%;
    }
    @media screen and (max-width: $screen-sm-max) {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: scroll;
      .spaces_card {
        display: flex;
        flex-basis: 42%;
        flex-shrink: 0;
        flex-grow: 0;
      }
    }
  }

  @media screen and (max-width: $screen-md-max) {
    &:not(.scc_scroll_swipe) {
      .spaces_card {
        &.sc_filled_grid {
          flex-basis: 50%;
        }
      }
    }
    &.scc_quad {
      .spaces_card  {
        flex-basis: 33.333%;
        flex-grow: 0;
      }
    }
  }
  @media screen and (max-width: $screen-sm-max) {
    &:not(.scc_scroll_swipe),
    &:not(.cta_row) {
      .spaces_card {
        flex-basis: 100%;
      }
    }
    &.cta_row {
      flex-wrap: wrap;
    }
    &.scc_tripple,
    &.scc_quad {
      .spaces_card  {
        flex-basis: 100%;
        flex-grow: 0;
      }
    }
    &.scc_search_results {
      .spaces_card {
        flex-basis: 100%;
        padding: 0 0 $padding_cells 0;
        .sc_main_title {
          font-weight: $font_weight_bold;
        }
      }

    }
  }
  @media screen and (max-width: $screen-xs-max) {
    &.scc_tripple,
    &.scc_quad {
      .spaces_card {
        flex-basis: 100%;
      }
    }
  }
}