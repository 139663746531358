@import "style_grid.scss";
@import "style_variables.scss";
@import "style_mixins.scss";
@import "jdes_icons.scss";
@import "jdes_icons_animated.scss";
@import "style_fonts.scss";
@import "style_entry_cards.scss";

* {
  margin: 0;
  padding: 0;
  font-family: $font_family_body;
  font-weight: $font_weight_light;
  box-sizing: border-box;
  outline: none;
  text-decoration: none;
  border: none;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;

}

.move_to_background {
  z-index: -1 !important;
}

html {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  font-size: $font_size_rem;
  overflow-x: hidden;
  @media screen and (max-width: $screen-sm-max) {
    font-size: $font_size_sm;
  }
}

body, html {
  width: 100%;
  line-height: 1.42;
  letter-spacing: 0.01em;
  min-height: 100vh;
  background-color: $color_soft_white;
  background-image: url("../images/tiled_background.png");
  background-attachment: fixed;
  background-size: 700px 1244px;
  @media screen and (max-width: $screen-sm-max) {
    line-height: 2;
  }
}

body {
  position: relative;
  display: block;
  z-index: 1;
}

#woroom_refuge {
  min-height: 100vh;
  display: flex;
  position: relative;
  z-index: 2;
  flex-direction: column;
  @media screen and (max-width: $screen-sm-max) {

  }
}

::-moz-selection {
  background: $color_main;
  color: $color_background_dark;
}

::selection {
  background: $color_main;
  color: $color_background_dark;
}

img, video, svg {
  max-width: 100%;
  vertical-align: middle;
}

strong, b {
  font-weight: $font_weight_black;
}

blockquote {
  font-size: $font-size-primary * 3;
  font-weight: $font_weight_light;
}

a, a:link, a:visited {
  text-decoration: none;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  z-index: 10;
}

h1, h2, h3, h4 {
  padding: 0;
  font-family: $font_family_body;
  font-weight: 400;
  color: $font_color_base;
  letter-spacing: 0.01em;
  line-height: 1.2;
  position: relative;
  clear: both;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  text-rendering: optimizeLegibility;
  margin: 0 0 0.25rem 0;

  span {
    font-size: $font-size-primary;
    display: block;
    font-weight: $font_weight_bold;
    padding-top: $padding_cells;
    opacity: 0.5;
    clear: both;
  }
}

h1 {
  font-size: $font_size_h1;
}

h2 {
  font-size: $font_size_h2;
}

h3 {
  font-size: $font_size_h3;
}

h4 {
  font-size: $font_size_h4;
}

.flex_spacer {
  flex-grow: 1;
}

p {
  margin-bottom: $padding_default;

  a, a:link, a:visited {
    color: $color_blue;
    font-weight: $font_weight_bold;
    text-decoration: underline;

    &:hover {
      color: $color_facebook;
    }
  }
}

.data_info {
  font-size: $font-size_additional_info;
  font-weight: $font_weight_bold;
  color: $bg_overlay_dark_medium;
}

ul {
  list-style: none;

  li {
    list-style-type: none;
    padding-left: $padding_default / 1.5;
    position: relative;

    &::before {
      @include jdes-icons;
      content: '\e90a';
      position: absolute;
      left: 0;
      color: $color_secondary;
      font-size: $font-size_additional_info;
      top: 0.15em;
      @media screen and (max-width: $screen-sm-max) {
        top: 0.7em;
      }
    }
  }

  padding-bottom: $padding_default;
}

.mod_info {
  display: inline-block;
  align-self: center;
  margin-left: $padding_default / 2;
  font-family: "Lucida Console", monospace;
  letter-spacing: 0;
  font-size: $font_size_micro;
  padding: $padding_cells $padding_cells * 2 $padding_cells / 2 $padding_cells * 2;
  font-weight: bold;
  border-radius: $border-radius-default / 2;
  background-color: $color_yellow;
}


.interaction_panel {
  margin-top: $padding_default / 2;
  display: flex;

  .btn {
    + .btn {
      margin-left: $padding_cells;
    }
  }

  &.mobile {
    @media screen and (min-width: $screen-lg-min) {
      display: none;
    }
  }
}

.set_interaction_buttons {
  order: -10;
  padding-bottom: $padding_default / 4;
  display: flex;
  flex-direction: row-reverse;
}

.list-bullet {
  @include list_item_default_style;

  + br {
    display: none;
  }
}

.underlined {
  display: inline-block;
  position: relative;
  box-shadow: inset 0 -3px 0 $color_main;

}

.colored {
  font-weight: $font_weight_bold;
  color: $color_main;
}

.inline-cite {
  font-size: $font_size_h2;
  color: $color_main;
  width: 100%;
  display: block;
  clear: both;
  text-align: center;

  &::before {
    content: "\"";
  }

  &::after {
    content: "\"";
  }

  & + br {
    display: none;
  }
}

.mini-text {
  font-size: $font_size_micro;
  font-weight: $font_weight_bold;
  display: block;
  clear: both;
  width: 100%;
  opacity: 0.42;
}

.intend {
  display: block;
  clear: both;
  padding-left: $padding_default / 2;
  border-left: 6px solid $color_accent;
}

.flex_row {
  display: flex;
}

.flex_column {
  display: flex;
  flex-direction: column;
}

.form_summary_field {
  label {
    font-size: $font_size_micro;
    font-weight: $font_weight_bold;
  }
}

.info_growl {
  font-size: $font_size_micro;
  font-weight: $font_weight_bold;
  align-self: center;
  background-color: $color_green;
  color: $color_white;
  border-radius: 5px;
  display: inline-block;
  padding: $padding_cells / 2 $padding_cells * 2 !important;
  margin-right: $padding_cells * 2;
}

.form_status {
  padding: $padding_cells * 2 $padding_default;
  text-align: center;
  background-color: $bg_overlay_dark_ultra_soft;
  border: 2px dotted $color_accent;
  border-radius: $border-radius-default / 2;
  font-size: $font_size_micro;
  font-weight: $font_weight_bold;
  margin: $padding_default / 2 0;
}


.key_value_row {
  display: flex;
  font-size: $font-size_additional_info;

  &.vertical {
    flex-direction: column;
  }

  &.horizontal {
    .key {
      flex-basis: 30%;
      flex-grow: 0;
      flex-shrink: 0;
      min-width: 0;
    }

    .value {
      padding-left: $padding_default / 2;
    }
  }

  .key {
    font-weight: bold;
  }

  &:hover {
    background-color: $bg_overlay_dark_ultra_soft;
  }
}

.multi_select {
  display: flex;

  .multi_select_core {
    display: flex;
    width: 100%;
    align-items: center;
    padding: $padding_default / 2 0;
    border-radius: $border-radius-default;
    background-color: $bg_overlay_dark_extra_soft;
    cursor: pointer;

    span {
      width: 100%;
      text-align: center;
      font-size: $font-size_additional_info;
      font-weight: $font_weight_bold;
      line-height: 1.2;

    }

    @include animated(all, $animation_speed_default, $cubic_01);

    &:active {
      transform: scale(0.96);
    }

    &:hover {
      background-color: $bg_overlay_dark_soft;
    }
  }

  &.active {
    .multi_select_core {
      background-color: $color_secondary;
      color: $color_white;
    }
  }
}

.multi_select_matrix {
  @media screen and (max-width: $screen-sm-max) {
    > div {
      flex-basis: 33.333% !important;
    }
  }
}

.text_green {
  color: $color_green;
  font-weight: $font_weight_bold;
}

.text_red {
  color: $color_red;
  font-weight: $font_weight_bold;
}

.text_black {
  color: $color_dark;
  font-weight: $font_weight_bold;
}