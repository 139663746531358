
$color_main: #2bb38a;
$color_secondary: #002B39;
$color_background_light: rgba(250,250,255,1);
$color_background_dark: #002537;

$color_dark: #25404d;
//$color_secondary: #123321;
$color_secondary_80: #fff;
$color_secondary_50: #fff;

@mixin bg_color_secondary_rgba($opacity) {
  background-color: rgba(0,23,31, $opacity)
}

$color_white: #fff;
$color_soft_white: #fcfcfc;

$color_accent: #ff6c00;

$color_gold_high: #e8d89e;
$color_gold_low: #806940;

$color_red: #e04356;
$color_pink: #ff0066;
$color_orange: #ff6c00;
$color_yellow: #ffd200;
$color_blue: #006bbd;
$color_green: #00aa72;

// Brands
$color_kununu: #99c613;
$color_twitter: #00ACEE;
$color_facebook: #3B5998;
$color_whatsapp: #25D366;
$color_xing: #126567;
$color_link_blue: #538ca6;
$color_viber: #7360f2;

$font_color_base: $color_background_dark;
$font_color_headline: $color_main;

$font_color_white: #f1f1f1;
$font_on_accent: $font_color_white;

$decent_menu_link_color: #555;

$bg_overlay_soft: rgba(255,255,255,0.05);
$bg_overlay_medium: rgba(255,255,255,0.1);
$bg_overlay_hard: rgba(255,255,255,0.15);
$bg_overlay_extra_hard: rgba(255,255,255,0.75);

$bg_overlay_dark_ultra_soft: rgba(37,64,77,0.02);
$bg_overlay_dark_extra_soft: rgba(37,64,77,0.05);
$bg_overlay_dark_soft: rgba(37,64,77,0.1);
$bg_overlay_dark_medium: rgba(37,64,77,0.6);
$bg_overlay_dark_hard: rgba(37,64,77,0.9);

$picture_aspect_padding: 139%;
$coverart_aspect_padding: 37.5%;

// General Scales
$max_content_width: 100%;
$banner_base_height: 75px;
$banner_scrolled_height: 85px;

$banner_mobile_height: 50px;




// General Styles
$font_size_rem: 18px;
$font_size_sm: 14px;
$font_size_micro: 12px;
$font_size_menu: $font_size_micro;
$font_size_buttons: $font_size_micro;

$font-size-primary: 1rem;
$font-size_additional_info: 0.85rem;
$font_size_h1: 2.25rem;
$font_size_h2: 1.5rem;
$font_size_h3: 1.25rem;
$font_size_h4: 1rem;

$padding_default: 2rem;
$padding_large_screen_sides: $padding_default * 2;
$padding_small_screen_sides: $padding_default / 2;
$padding_cells: 5px;

$card_padding_outer: $padding_default / 8;
$collection_padding_outer: $card_padding_outer;
$card_padding_inner: $padding_default / 2;

$border-radius-micro: 0.125rem;
$border-radius-default: 0.5rem;
$border-radius-extra: 0.65rem;
$box_shadow_default: 5px 5px 10px rgba(0,0,0,0.4);
$box_shadow_small: 4px 4px 8px rgba(0, 0, 0, 0.26);

$default_container_padding: $padding_default;
$default_row_padding: $padding_default * 5 0 $padding_default * 5 0;
$default_row_padding_sm: $padding_default * 2 0 $padding_default * 2 0;

// Fonts
$font_family_headline: 'Encode Sans Semi Condensed', sans-serif;
$font_family_body: 'Raleway', sans-serif;
$font_weight_light: 300;
$font_weight_normal: 400;
$font_weight_bold: 600;
$font_weight_black: 700;



$font-shadow-headline: 2px 2px 12px rgba(0,0,0,0.9);

// Buttons
$button_default_font_color: #fff;
$button_default_background_color: $color_accent;
$button_default_hover_color: $color_accent;
$button_border_radius: .25rem;

$animation_speed_fast: 0.12s;
$animation_speed_default: 0.21s;
$animation_speed_slow: 0.42s;
$animation_speed_extra_slow: 0.82s;




