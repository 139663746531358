@import "style_mixins";

@font-face {
  font-family: 'jdes-icons';
  src:  url('../fonts/jdes-icons.eot?5na7i6');
  src:  url('../fonts/jdes-icons.eot?5na7i6#iefix') format('embedded-opentype'),
  url('../fonts/jdes-icons.ttf?5na7i6') format('truetype'),
  url('../fonts/jdes-icons.woff?5na7i6') format('woff'),
  url('../fonts/jdes-icons.svg?5na7i6#jdes-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="jdes-icons"], [class*=" jdes-icons"], .jdes-icons {
  @include jdes-icons();
}


.jdes-icons {
  display: inline-block;

  &.jdes-icons-menu-burger:before {
    content: "\e902";
  }
  &.jdes-icons-add:before {
    content: "\e903";
  }
  &.jdes-icons-add-circle:before {
    content: "\e904";
  }
  &.jdes-icons-lock:before {
    content: "\e905";
  }
  &.jdes-icons-more-vert:before {
    content: "\e906";
  }
  &.jdes-icons-apps:before {
    content: "\e907";
  }
  &.jdes-icons-settings:before {
    content: "\e908";
  }
  &.jdes-icons-notifications:before {
    content: "\e909";
  }
  &.jdes-icons-infinity:before {
    content: "\e90a";
  }
  &.jdes-icons-star:before {
    content: "\e90b";
  }
  &.jdes-icons-verified-user:before {
    content: "\e90c";
  }
  &.jdes-icons-chevron-left:before {
    content: "\e90d";
  }
  &.jdes-icons-chevron-right:before {
    content: "\e90e";
  }
  &.jdes-icons-close:before {
    content: "\e90f";
  }
  &.jdes-icons-menu-burger-top:before {
    content: "\e910";
  }
  &.jdes-icons-menu-burger-center:before {
    content: "\e911";
  }
  &.jdes-icons-menu-burger-bottom:before {
    content: "\e912";
  }
  &.jdes-icons-checkbox-empty:before {
    content: "\e914";
  }
  &.jdes-icons-checkbox-checked:before {
    content: "\e915";
  }
  &.jdes-icons-checkbox-empty-filled:before {
    content: "\e916";
  }
  &.jdes-icons-checkbox-filled-checked:before {
    content: "\e917";
  }
  &.jdes-icons-checkbox-frame:before {
    content: "\e918";
  }
  &.jdes-icons-checkbox-hook:before {
    content: "\e919";
  }
  &.jdes-icons-hook:before {
    content: "\e91a";
  }
  &.jdes-icons-notification-circle:before {
    content: "\e91b";
  }
  &.jdes-icons-notification-circle-empty:before {
    content: "\e91c";
  }
  &.jdes-icons-heart-outline:before {
    content: "\e91d";
  }
  &.jdes-icons-facebook:before {
    content: "\e931";
  }
  &.jdes-icons-fronetic_hexagon:before {
    content: "\e900";
  }
  &.jdes-icons-fronetic_hexagon_filled:before {
    content: "\e901";
  }
  &.jdes-icons-fronetic_bildmarke:before {
    content: "\e913";
  }
  &.jdes-icons-theroom:before {
    content: "\e900";
  }
  &.jdes-icons-globe:before {
    content: "\e91e";
  }
  &.jdes-icons-search:before {
    content: "\e91f";
  }
  &.jdes-icons-office:before {
    content: "\e920";
  }
  &.jdes-icons-login:before {
    content: "\e922";
  }
  &.jdes-icons-mail:before {
    content: "\e923";
  }
  &.jdes-icons-rooms-general:before {
    content: "\e924";
  }
  &.jdes-icons-rooms-office:before {
    content: "\e925";
  }
  &.jdes-icons-rooms-storage:before {
    content: "\e929";
  }
  &.jdes-icons-rooms-workshop:before {
    content: "\e92a";
  }
  &.jdes-icons-map_marker:before {
    content: "\e92b";
  }
  &.jdes-icons-filter:before {
    content: "\e92c";
  }

}




