@import "../../../assets/styles/style_variables.scss";
@import "../../../assets/styles/style_mixins.scss";

.startpage_counter {
  @include gradient($color_main, $color_secondary, -180deg);
  padding: $padding_default * 2 0;
  margin: $padding_default * 2 0;
  color: $color_white;
}

.startpage_summary_card {
  .startpage_summary_card_core{
    display: flex;
    border: 4px solid $bg_overlay_medium;
    background-color: $bg_overlay_medium;
    padding-top: 100%;
    position: relative;
    border-radius: $border-radius-default * 7;
    .startpage_summary_card_content {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      transform: translateY(-50%);
      text-shadow: 1px 1px 5px rgba(0,0,0,0.8);
      text-align: center;
      .the_counter {
        font-size: $font_size_h1 * 1.5;
        font-weight: $font_weight_bold;
        @include ellipsis;
      }
      .the_title {
        font-weight: $font_weight_bold;
      }
    }
    @media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) {

        border-radius: $border-radius-default * 4;
        .startpage_summary_card_content {
          .the_counter {
            font-size: $font_size_h1;

          }
          .the_title {
            font-size: $font-size_micro;
          }
        }

    }
  }
  @media screen and (max-width: $screen-sm-max){
    max-width: 50% !important;
    .startpage_summary_card_core {
      border-radius: $border-radius-default * 4;
      padding-top: 75%;
      overflow: hidden;
      .startpage_summary_card_content {
        .the_counter {
          font-size: $font_size_h2;
          line-height: 1.1;
        }
        .the_title {
          font-size: $font-size_micro;
        }
      }
    }
  }
}

.block.cta {
  @include gradient($color_main, $color_dark, 45deg);
  padding-top: $padding_default * 3;
  padding-bottom: $padding_default * 3;
  margin-top: $padding_default;
  .block_core {
    h2 {
      text-align: center;
      font-size: $font_size_h1;
      color: $color_white;
    }
    .btn {
      font-size: $font_size_h2;
      padding: $padding_default / 2 $padding_default * 2;
      border-radius: $border-radius-default;
      color: $color_white;
      border-color: $bg_overlay_medium;
      line-height: 1.15;
      &:hover {
        background-color: $bg_overlay_soft;
      }
    }
    text-align: center;
  }
  & + footer {
    margin-top: 0;
  }
}